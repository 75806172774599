<template>
    <div>
        <div class="custom-search d-flex">
            <div class="d-flex mr-auto">
                <b-card-text style="font-size: 16px">
                    Click on date to check people's mileage report
                </b-card-text>
            </div>
            <div class="d-flex align-items-center">
                <b-form-group>
                    <b-form-input
                        v-model="searchTerm"
                        placeholder="Search"
                        type="text"
                        class="d-inline-block"
                    />
                </b-form-group>
            </div>
        </div>
        <vue-good-table
            v-if="rows.length > 0"
            :columns="columns"
            :rows="rows"
            :rtl="direction"
            :search-options="{
                enabled: true,
                externalQuery: searchTerm,
            }"
            :select-options="{
                enabled: false,
                selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
                selectionInfoClass: 'custom-class',
                selectionText: 'rows selected',
                clearSelectionText: 'clear',
                disableSelectInfo: true, // disable the select info panel on top
                selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
            }"
            :pagination-options="{
                enabled: true,
                perPage: pageLength,
            }"
        >
            <template slot="table-row" slot-scope="props">
                <span
                    v-if="props.column.field === 'date'"
                    v-b-tooltip.hover.right
                    title="Click to check report!"
                    style="cursor: pointer"
                    @click="$router.push('/reports/mileage/' + props.row.date)"
                >
                    {{ props.row.date | monthYearFormat }}
                </span>
                <span v-else-if="props.column.field === 'attachment'">
                    <b-avatar variant="light-secondary">
                        <b-button
                            variant="flat-secondary"
                            class="btn-icon rounded-circle"
                            @click="
                                openPreview(
                                    '/' +
                                        props.row.attachment
                                            .split('/')
                                            .slice(3, 10)
                                            .join('/')
                                )
                            "
                        >
                            <feather-icon
                                v-b-tooltip.hover.bottom="'View report'"
                                icon="EyeIcon"
                                size="16"
                            />
                        </b-button>
                    </b-avatar>
                    <b-avatar variant="light-secondary" class="ml-50">
                        <b-button
                            variant="flat-secondary"
                            class="btn-icon rounded-circle"
                            @click="
                                downloadPreview(
                                    '/' +
                                        props.row.attachment
                                            .split('/')
                                            .slice(3, 10)
                                            .join('/')
                                )
                            "
                        >
                            <feather-icon
                                v-b-tooltip.hover.bottom="'Download report'"
                                icon="DownloadIcon"
                                size="16"
                            />
                        </b-button>
                    </b-avatar>
                </span>
                <span v-else>
                    {{ props.formattedRow[props.column.field] }}
                </span>
            </template>

            <!-- pagination -->
            <template slot="pagination-bottom" slot-scope="props">
                <div class="d-flex justify-content-between flex-wrap">
                    <div class="mt-43">Total: {{ rows.length }}</div>
                    <div>
                        <b-pagination
                            :value="1"
                            :total-rows="props.total"
                            :per-page="pageLength"
                            first-number
                            last-number
                            align="right"
                            prev-class="prev-item"
                            next-class="next-item"
                            class="mt-1 mb-0"
                            @input="
                                (value) =>
                                    props.pageChanged({ currentPage: value })
                            "
                        >
                            <template #prev-text>
                                <feather-icon
                                    icon="ChevronLeftIcon"
                                    size="18"
                                />
                            </template>
                            <template #next-text>
                                <feather-icon
                                    icon="ChevronRightIcon"
                                    size="18"
                                />
                            </template>
                        </b-pagination>
                    </div>
                </div>
            </template>
        </vue-good-table>
        <div v-else>
            <b-alert variant="secondary" show>
                <div class="alert-body mt-1" style="margin-bottom: -14px">
                    <span><strong>Sorry!</strong> No results found.</span>
                </div>
            </b-alert>
        </div>
        <b-modal
            id="modal-preview"
            centered
            title="Report preview"
            ok-only
            ok-title="Close"
            size="xl"
        >
            <b-card-text>
                <div id="tableHost" />
            </b-card-text>
        </b-modal>
    </div>
</template>

<script>
import {
    BPagination,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BButton,
    BCard,
    VBTooltip,
    BTooltip,
    BAvatar,
    BCardText,
    BAlert,
} from "bootstrap-vue"
import { VueGoodTable } from "vue-good-table"
import store from "@/store/index"
import Km from "@/api/services/Km"
import Ripple from "vue-ripple-directive"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
import "@grapecity/wijmo.vue2.grid"
import "@grapecity/wijmo.styles/wijmo.css"
import * as wjcCore from "@grapecity/wijmo"
import * as wjcXlsx from "@grapecity/wijmo.xlsx"

export default {
    name: "KmsReportTable",
    components: {
        VueGoodTable,
        BPagination,
        BFormGroup,
        BFormInput,
        BRow,
        BCol,
        BButton,
        BCard,
        ToastificationContent,
        VBTooltip,
        BTooltip,
        BAvatar,
        BCardText,
        BAlert,
    },
    directives: {
        Ripple,
        "b-tooltip": VBTooltip,
    },
    data() {
        return {
            pageLength: 100,
            dir: false,
            columns: [
                {
                    label: "Date",
                    field: "date",
                    tdClass: "height-50",
                    dateInputFormat: "yyyy-mm-dd",
                    dateOutputFormat: "dd-mm-yyyy",
                },
                {
                    label: "Report",
                    field: "attachment",
                },
            ],
            rows: [],
            searchTerm: "",
            workbook: null,
            sheetIndex: -1,
        }
    },
    computed: {
        direction() {
            if (store.state.appConfig.isRTL) {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.dir = true
                return this.dir
            }
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.dir = false
            return this.dir
        },
    },
    created() {
        this.loadData()
    },
    methods: {
        downloadPreview(link) {
            this.$loading(true)
            Km.getFile(link).then((response) => {
                const url = window.URL.createObjectURL(
                    new Blob([response.data])
                )
                const link = document.createElement("a")
                link.href = url
                link.setAttribute("download", "mileage-report.xlsx")
                document.body.appendChild(link)
                link.click()
                setTimeout(() => {
                    this.$loading(false)
                }, 1000)
            })
        },
        openPreview(link) {
            this._loadWorkbook(link)
            this.$bvModal.show("modal-preview")
        },
        tabClicked(e, index) {
            e.preventDefault()
            this._drawSheet(index)
        },
        _drawSheet(sheetIndex) {
            const drawRoot = document.getElementById("tableHost")
            drawRoot.textContent = ""
            this.sheetIndex = sheetIndex
            this._drawWorksheet(this.workbook, sheetIndex, drawRoot, 200, 100)
        },
        _loadWorkbook(link) {
            // let reader = new FileReader()
            // reader.onload = (e) => {
            //   let workbook = new wjcXlsx.Workbook();
            //   workbook.loadAsync(reader.result, (result) => {
            //     this.workbook = result
            //     this._drawSheet(this.workbook.activeWorksheet || 0)
            //   })
            // }
            Km.getFile(link).then((res) => {
                // let blob = res.data;
                // let reader = new FileReader();

                // var self = this
                // reader.onload = (e) => {
                //   self.$refs.excelViewer.openExcelData(e.target.result)
                // };
                // reader.readAsBinaryString(blob)
                // reader.readAsDataURL(blob)
                // reader.readAsBinaryString(blob);
                // let file = document.getElementById('importFile').files[0]
                // if (file) {
                //   reader.readAsDataURL(file)
                // }
                const reader = new FileReader()
                reader.onload = (e) => {
                    const workbook = new wjcXlsx.Workbook()
                    workbook.loadAsync(reader.result, (result) => {
                        this.workbook = result
                        this._drawSheet(this.workbook.activeWorksheet || 0)
                    })
                }
                const file = res.data
                if (file) {
                    reader.readAsDataURL(file)
                }
            })
        },
        _drawWorksheet(workbook, sheetIndex, rootElement, maxRows, maxColumns) {
            if (
                !workbook ||
                !workbook.sheets ||
                sheetIndex < 0 ||
                workbook.sheets.length == 0
            ) {
                return
            }
            sheetIndex = Math.min(sheetIndex, workbook.sheets.length - 1)
            if (maxRows == null) {
                maxRows = 200
            }
            if (maxColumns == null) {
                maxColumns = 100
            }
            // Namespace and XlsxConverter shortcuts.
            const sheet = workbook.sheets[sheetIndex]
            const defaultRowHeight = 20
            const defaultColumnWidth = 60
            const tableEl = document.createElement("table")
            tableEl.border = "1"
            tableEl.style.borderCollapse = "collapse"
            let maxRowCells = 0
            for (let r = 0; sheet.rows && r < sheet.rows.length; r++) {
                if (sheet.rows[r] && sheet.rows[r].cells) {
                    maxRowCells = Math.max(
                        maxRowCells,
                        sheet.rows[r].cells.length
                    )
                }
            }
            // add columns
            const columns = sheet.columns || []
            const invisColCnt = columns.filter(
                (col) => col.visible === false
            ).length

            if (sheet.columns) {
                maxRowCells = Math.min(
                    Math.max(maxRowCells, columns.length),
                    maxColumns
                )
                for (let c = 0; c < maxRowCells; c++) {
                    const col = columns[c]
                    if (col && !col.visible) {
                        continue
                    }
                    const colEl = document.createElement("col")
                    tableEl.appendChild(colEl)
                    let colWidth = `${defaultColumnWidth}px`
                    if (col) {
                        this._importStyle(colEl.style, col.style)
                        if (col.autoWidth) {
                            colWidth = ""
                        } else if (col.width != null) {
                            colWidth = `${col.width}px`
                        }
                    }
                    colEl.style.width = colWidth
                }
            }
            const rowCount = Math.min(maxRows, sheet.rows.length)
            for (let r = 0; sheet.rows && r < rowCount; r++) {
                const row = sheet.rows[r]
                let cellsCnt = 0 // including colspan

                if (row && !row.visible) {
                    continue
                }
                const rowEl = document.createElement("tr")
                tableEl.appendChild(rowEl)

                if (row) {
                    this._importStyle(rowEl.style, row.style)
                    if (row.height != null) {
                        rowEl.style.height = `${row.height}px`
                    }
                    for (let c = 0; row.cells && c < row.cells.length; c++) {
                        const cell = row.cells[c]
                        const cellEl = document.createElement("td")
                        const col = columns[c]

                        if (col && !col.visible) {
                            continue
                        }
                        cellsCnt++
                        rowEl.appendChild(cellEl)
                        if (cell) {
                            this._importStyle(cellEl.style, cell.style)
                            let { value } = cell

                            if (!(value == null || value !== value)) {
                                // TBD: check for NaN should be eliminated
                                if (
                                    wjcCore.isString(value) &&
                                    value.charAt(0) == "'"
                                ) {
                                    value = value.substr(1)
                                }
                                let netFormat = ""
                                if (cell.style && cell.style.format) {
                                    netFormat = wjcXlsx.Workbook.fromXlsxFormat(
                                        cell.style.format
                                    )[0]
                                }
                                const fmtValue = netFormat
                                    ? wjcCore.Globalize.format(value, netFormat)
                                    : value
                                cellEl.innerHTML = wjcCore.escapeHtml(fmtValue)
                            }

                            if (cell.colSpan && cell.colSpan > 1) {
                                cellEl.colSpan = this._getVisColSpan(
                                    columns,
                                    c,
                                    cell.colSpan
                                )
                                cellsCnt += cellEl.colSpan - 1
                                c += cell.colSpan - 1
                            }

                            if (cell.note) {
                                wjcCore.addClass(cellEl, "cell-note")
                                cellEl.title = cell.note.text
                            }
                        }
                    }
                }
                // pad with empty cells
                const padCellsCount = maxRowCells - cellsCnt - invisColCnt
                for (let i = 0; i < padCellsCount; i++) {
                    rowEl.appendChild(document.createElement("td"))
                }
                if (!rowEl.style.height) {
                    rowEl.style.height = `${defaultRowHeight}px`
                }
            }
            // do it at the end for performance
            rootElement.appendChild(tableEl)
        },
        _getVisColSpan(columns, startFrom, colSpan) {
            let res = colSpan
            for (
                let i = startFrom;
                i < columns.length && i < startFrom + colSpan;
                i++
            ) {
                const col = columns[i]
                if (col && !col.visible) {
                    res--
                }
            }
            return res
        },
        _importStyle(cssStyle, xlsxStyle) {
            if (!xlsxStyle) {
                return
            }
            if (xlsxStyle.fill) {
                if (xlsxStyle.fill.color) {
                    cssStyle.backgroundColor = xlsxStyle.fill.color
                }
            }
            if (xlsxStyle.hAlign && xlsxStyle.hAlign != wjcXlsx.HAlign.Fill) {
                cssStyle.textAlign =
                    wjcXlsx.HAlign[xlsxStyle.hAlign].toLowerCase()
            }
            const { font } = xlsxStyle
            if (font) {
                if (font.family) {
                    cssStyle.fontFamily = font.family
                }
                if (font.bold) {
                    cssStyle.fontWeight = "bold"
                }
                if (font.italic) {
                    cssStyle.fontStyle = "italic"
                }
                if (font.size != null) {
                    cssStyle.fontSize = `${font.size}px`
                }
                if (font.underline) {
                    cssStyle.textDecoration = "underline"
                }
                if (font.color) {
                    cssStyle.color = font.color
                }
            }
        },
        loadData() {
            this.$loading(true)
            this.rows = []
            Km.getKmReport().then((response) => {
                this.rows = response.data.results
                this.$loading(false)
            })
        },
    },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
